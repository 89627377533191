<template>
    <form @submit.prevent="onSubmit">
        <div class="reset-form">
          <div class="err"></div>
        <!-- Password -->

        <VField>
          <VControl icon="feather:lock">
            <input
                id="password"
                v-model="password"
                class="input"
                name="password"
                type="password"
                placeholder="Password"
                autocomplete="password"
            />
          </VControl>
        </VField>

        <!-- Password -->
        <VField>
          <VControl icon="feather:lock">
            <input
                id="passwordConfirm"
                v-model="confirm_password"
                class="input"
                name="confirm_password"
                type="password"
                placeholder="Confirm Password"
                autocomplete="passwordConfirm"
            />
            <p v-if="!new_passwordmatch" class="tag is-danger is-fullwidth"><small class="error-text ">Wrong password</small></p>
          </VControl>
        </VField>

        <!-- Submit -->
        <VControl class="reset">
            <VButton
                :loading="isLoading"
                color="primary"
                type="submit"
                bold
                fullwidth
                raised
            >
            Change password
            </VButton>
        </VControl>

        <div class="forgot-link has-text-centered">
            <router-link :to="{ name: 'login' }" class="has-text-white">Back to login</router-link>
        </div>
        </div>
    </form>
</template>

<script>
import { ref } from 'vue';
import VButton from '../../components/base/button/VButton.vue'
import VField from '../../components/base/form/VField.vue'
import VControl from '../../components/base/form/VControl.vue'
import {useRouter, useRoute, RouteParamValue} from 'vue-router'
import store, {ActionTypes} from "../../store";
export default {
  name: 'ResetForm',
  components: {
    VButton,
    VField,
    VControl
  },
  setup() {
      const password = ref('');
      const confirm_password = ref('');
      const route=useRoute()
      const router=useRouter();
      let new_passwordmatch=ref(true)
      const onSubmit = () => {
        if ((password.value === confirm_password.value) &&(route.query.token)&&(password.value!=='') && (confirm_password.value!=='')) {
          store.dispatch(ActionTypes.CHANGE_PASSWORD, {
            'password': password.value,
            'token':route.query.token
          }).then(() => {
            router.push({name: 'login'})
          })
        } else{
          new_passwordmatch.value=!new_passwordmatch.value
        }

      }

      return {
        password,
        confirm_password,
        new_passwordmatch,
        onSubmit
      }
  }
};
</script>


<style lang="scss">
@import '../../scss/abstracts/_variables.scss';
@import '../../scss/abstracts/_mixins.scss';
@import '../../scss/pages/auth/_auth.scss';




form .svgContainer {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto 1em;
  border-radius: 50%;
  background: none;
  border: solid 2.5px #3A5E77;
  overflow: hidden;
  pointer-events: none;
  display: none;
}
form .svgContainer div {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
}
form .svgContainer .mySVG {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}


</style>
